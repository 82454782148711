/*
 * Public API Surface of angular2-hotkeys
 */

export * from './lib/hotkeys.service';
export * from './lib/hotkeys.directive';
export * from './lib/hotkeys-cheatsheet/hotkeys-cheatsheet.component';
export * from './lib/hotkey.model';
export * from './lib/hotkey.options';
export * from './lib/hotkey.module';
